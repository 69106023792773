import './HomePage.less';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  Carousel,
  Card,
  Space,
  Form,
  Row,
  Col,
  message,
  Input,
  Select,
  Button,
  TextArea,
} from '@vs/vsf-kit';
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import downLoad from '@/assets/icon/downLoad.png';
import one from '@/assets/icon/atom-02.png';
import two from '@/assets/icon/sliders-02.png';
import three from '@/assets/icon/heart-hand.png';
import four from '@/assets/icon/shield-tick.png';

import newImg from '@/assets/image/news.png';
import calanderIcon from '@/assets/icon/calanderIcon.png';

import productScale from '@/assets/image/productScale.png';
import productScaleEn from '@/assets/image/productScaleEn.png';
import productThreeScale from '@/assets/image/productThreeScale.png';
import productThreeScaleEn from '@/assets/image/productThreeScaleEn.png';
import productTwoScale from '@/assets/image/productTwoScale.png';
import productTwoScaleEn from '@/assets/image/productTwoScaleEn.png';
import productOneScale from '@/assets/image/productOneScale.png';
import productOneScaleEn from '@/assets/image/productOneScaleEn.png';
import homeSwiperpng from '@/assets/image/homeSwiperpng.png';
import oneImg from '@/assets/image/oneImg.png';
import twoImg from '@/assets/image/twoImg.jpg';
import threeImg from '@/assets/image/threeImg.png';
import fourImg from '@/assets/image/fourImg.png';
import bottomPhoto from '@/assets/image/bottomPhoto.png';

import { getMonthName } from '@/utils/index';

import storage from '@/utils/storage';
import { getImages, getLabel, isChinese } from '@/config/zhCn_config';

const monthNames = [
  '一月',
  '二月',
  '三月',
  '四月',
  '五月',
  '六月',
  '七月',
  '八月',
  '九月',
  '十月',
  '十一月',
  '十二月',
];

const selectOptions = [
  {
    label: '购买',
    value: 'BUY',
  },
  {
    label: '售后',
    value: 'SALES',
  },
  {
    label: '合作',
    value: 'COOPERATE',
  },
];

const selectOptionsEn = [
  {
    label: 'Purchase product',
    value: 'BUY',
  },
  {
    label: 'After-sales',
    value: 'SALES',
  },
  {
    label: 'Become a Distributor',
    value: 'COOPERATE',
  },
];

const advantageList = [
  {
    // url: oneImg,
    title: '科技驱动先锋',
    content: '利用先进技术，推动光储领域的发展',
    enTitle: 'Leading Technology',
    enContent: 'Advancing the PV and ESS product\nwith Innovative Technologies',
  },
  {
    // url: twoImg,
    title: '多元产品矩阵',
    content: '提供多样化的产品选择，满足不同需求',
    enTitle: 'Diverse Array',
    enContent: 'Offering diverse product selections to\nmeet varied demands',
  },
  {
    // url: fourImg,
    title: '综合解决方案',
    content: '整合各类资源，为用户提供一站式服务',
    enTitle: 'Comprehensive Solutions',
    enContent: 'Advancing the PV and ESS product\nwith Innovative Technologies',
  },
  {
    // url: threeImg,
    title: '全球布局战略',
    content: '着眼全球市场，进行本地化战略布局',
    enTitle: 'Global Distribution',
    enContent:
      'Global Market Vision, Carrying Out\nLocalization Strategic Planning',
  },
];

const productList = [
  {
    url: productScale,
    enUrl: productScaleEn,
    link: './photovoltaic',
  },
  {
    url: productThreeScale,
    enUrl: productThreeScaleEn,
    link: './productDetail?type=robot',
  },
  {
    url: productOneScale,
    enUrl: productOneScaleEn,
    link: './productDetail?type=powerPlant',
  },
  {
    url: productTwoScale,
    enUrl: productTwoScaleEn,
    link: './productDetail?type=microgrid',
  },
];

const voiceList = [
  {
    name: '陈冠希',
    position: 'CEO',
    company: '某某公司',
    content:
      '“多年来，Westeria 在产品质量和服务方面一直是我们可靠的合作伙伴。WeKea 是我们更快、更准确地为客户提供服务的完美补充，我们认为 WeKea 是未来不可或缺的销售工具。”',
  },
  {
    name: '彭于晏',
    position: 'CEO',
    company: '某某公司',
    content:
      '“多年来，Westeria 在产品质量和服务方面一直是我们可靠的合作伙伴。WeKea 是我们更快、更准确地为客户提供服务的完美补充，我们认为 WeKea 是未来不可或缺的销售工具。”',
  },
  {
    name: '吴亦凡',
    position: 'CEO',
    company: '某某公司',
    content:
      '“多年来，Westeria 在产品质量和服务方面一直是我们可靠的合作伙伴。WeKea 是我们更快、更准确地为客户提供服务的完美补充，我们认为 WeKea 是未来不可或缺的销售工具。”',
  },
  {
    name: '周杰伦',
    position: 'CEO',
    company: '某某公司',
    content:
      '“多年来，Westeria 在产品质量和服务方面一直是我们可靠的合作伙伴。WeKea 是我们更快、更准确地为客户提供服务的完美补充，我们认为 WeKea 是未来不可或缺的销售工具。”',
  },
];
const HomePage = (props) => {
  const [form] = Form.useForm();

  const swiperList = [
    {
      frontImg: homeSwiperpng,
      title: getLabel('lingtanweilai'),
      content: getLabel('lingtanweilaiContent'),
    },
  ];
  const [newsList, setNewsList] = useState([]);
  const [swiperImg, setSwiperImg] = useState([]);
  const [advantageIndex, setAdvantageIndex] = useState(0);

  // 数据
  const [imageData, setImageData] = useState([]);

  // 获取banner图
  useEffect(async () => {
    const res =
      await vsf?.services?.FrontPageController_getAllByFrontPageQto_bafe67?.({
        qto: {
          from: 0,
          size: 1000,
        },
      });
    if (res?.data) {
      setImageData(res?.data);
    }
  }, []);

  // 首页banner
  useEffect(() => {
    if (imageData.length !== 0) {
      if (isChinese()) {
        // 中文
        setSwiperImg(JSON.parse(imageData[0]?.frontImg));
        // console.log(JSON.parse(imageData[0]?.frontImg), 'imageData');
        advantageList?.forEach((item, index) => {
          item.url = JSON.parse(imageData[0].advantageImg)[index];
        });
      } else {
        // 英文
        setSwiperImg(JSON.parse(imageData[0]?.enFrontImg));
        advantageList?.forEach((item, index) => {
          item.url = JSON.parse(imageData[0].enAdvantageImg)[index];
        });
      }
    }
  }, [imageData, isChinese()]);

  const loadSwiperInfo = () => {
    vsf?.services
      ?.FrontPageController_getAllByFrontPageQto_bafe67?.({
        qto: {
          from: 0,
          size: 1000,
        },
        ext: {},
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          setSwiperList(resposne?.data);
        }
      });
  };

  const loadSDataInfo = () => {
    vsf?.services
      ?.InformationManagementController_getPagedByInformationInfoQto_a268b7?.({
        qto: {
          from: 0,
          size: 3,
        },
        ext: {},
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          const date = resposne?.data?.result?.map((item) => {
            return {
              ...item,
              dateString: item?.pubTime
                ? getMonthName(item?.pubTime ?? '')
                : '',
            };
          });
          setNewsList(date);
        }
      });
  };

  const onFinish = (values) => {
    vsf?.services
      ?.MessageController_createMessage_4501e0?.({
        btoParam: values,
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          message.success('提交成功！');
          form?.resetFields();
        }
      });
  };

  useEffect(() => {
    // loadSwiperInfo();
    loadSDataInfo();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Section>
      <div className="HomePageContainer">
        <div className="HomePageContainer_swiper">
          <Carousel
            autoplay
            // afterChange={onChange}
            autoplaySpeed={3000}
            dots={false}
            arrows={true}
            draggable={true}
            adaptiveHeight
            infinite
            fade
          >
            {/* <Carousel afterChange={onChange}> */}
            {swiperImg.map((item, index) => {
              // return <div key={index}>1</div>;
              return <img src={item} key={index} />;
            })}
          </Carousel>

          {/* {swiperList?.map((item, index) => {
            return (
              <>
              <div
            className="HomePageContainer_swiper__item"
            style={{
              // backgroundImage: `url(${item?.frontImg})`,
              // backgroundImage: `url(${getImages('homeBanner')})`,
              backgroundImage: `url(${swiperImg})`,
            }}
            // key={index}
          >
            <div className="HomePageContainer_swiper__item__title">
              {item?.title}
            </div>
            <div className="HomePageContainer_swiper__item__content">
              {item?.content}
            </div>
            <div className="HomePageContainer_swiper__item__bottom">
              <div className="HomePageContainer_swiper__item__bottom__text">
                {getLabel('tiyanchuangxin')}
              </div>
              <img
                src={downLoad}
                alt=""
                className="HomePageContainer_swiper__item__bottom__icon"
              />
            </div>
          </div>
          </>
            );
          })} */}
        </div>

        <div className="HomePageContainer_introduce">
          <div className="HomePageContainer_introduce__left">
            <div className="HomePageContainer_introduce__left__title">
              What We Do
            </div>
            <div className="HomePageContainer_introduce__left__text">
              {getLabel('lingtanzhilu')}
            </div>
          </div>
          <div
            className="HomePageContainer_introduce__right"
            style={
              isChinese() ? { fontSize: '2.25rem' } : { fontSize: '1.5rem' }
            }
          >
            {getLabel('wulinninshi')}
          </div>
        </div>

        <div className="HomePageContainer_advantage">
          <div className="HomePageContainer_advantage__box">
            {advantageList?.map((item, index) => {
              return (
                <>
                  <div
                    className={
                      advantageIndex === index
                        ? 'HomePageContainer_advantage__box__itemChoose'
                        : 'HomePageContainer_advantage__box__item'
                    }
                    onMouseEnter={() => {
                      setAdvantageIndex(index);
                    }}
                  >
                    <div
                      className={
                        advantageIndex === index
                          ? 'HomePageContainer_advantage__box__itemConChoose'
                          : 'HomePageContainer_advantage__box__itemCon'
                      }
                    >
                      <img
                        className="HomePageContainer_advantage__box__item__icon"
                        src={
                          index + 1 === 1
                            ? one
                            : index + 1 === 2
                              ? two
                              : index + 1 === 3
                                ? three
                                : index + 1 === 4
                                  ? four
                                  : null
                        }
                      ></img>
                      <div
                        className={
                          advantageIndex === index
                            ? 'HomePageContainer_advantage__box__item__textChoose'
                            : 'HomePageContainer_advantage__box__item__text'
                        }
                      >
                        <div>{isChinese() ? item?.title : item?.enTitle}</div>
                        <div>
                          {isChinese() ? item?.content : item?.enContent}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <img
            loading="lazy"
            src={advantageList[advantageIndex]?.url}
            alt=""
            className="HomePageContainer_advantage__img"
          />
          {/* <div className="HomePageContainer_advantage__numBox">
            {advantageList?.map((item, index) => {
              return (
                <>
                  <div
                    className={
                      advantageIndex === index
                        ? 'HomePageContainer_advantage__numBox__numChoose'
                        : 'HomePageContainer_advantage__numBox__num'
                    }
                  >{`0${index + 1}`}</div>
                </>
              );
            })}
          </div> */}
        </div>

        <div className="HomePageContainer_product">
          <div className="HomePageContainer_product__left">
            <div className="HomePageContainer_product__left__title">
              Main Product
            </div>
            <div className="HomePageContainer_product__left__text">
              {getLabel('womende')}
              <br></br>
              {getLabel('hexinchanpin')}
            </div>
          </div>
          <div className="HomePageContainer_product__box">
            {productList?.map((item, index) => {
              return (
                <>
                  <div
                    className="HomePageContainer_product__boxCon"
                    onClick={() => {
                      vsf?.navigateTo(item?.link);
                    }}
                  >
                    <img
                      loading="lazy"
                      className="HomePageContainer_product__box__item"
                      key={index}
                      src={isChinese() ? item?.url : item?.enUrl}
                    ></img>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        {/* <div className="HomePageContainer_voice">
          <div className="HomePageContainer_voice__left">
            <div className="HomePageContainer_voice__left__title">
              We Believe
            </div>
            <div className="HomePageContainer_voice__left__text">
              我们创造信任<br></br>来自行业的声音
            </div>
          </div>

          <div className="HomePageContainer_voice__box">
            {voiceList?.map((item, index) => {
              return (
                <>
                  <div
                    className="HomePageContainer_voice__box__item"
                    key={index}
                  >
                    <div className="HomePageContainer_voice__box__item__title">
                      <div className="HomePageContainer_voice__box__item__title__num">{`0${
                        index + 1
                      }`}</div>
                      <div className="HomePageContainer_voice__box__item__title__dots"></div>
                    </div>

                    <div className="HomePageContainer_voice__box__item__content">
                      {item?.content}
                    </div>
                    <div className="HomePageContainer_voice__box__item__footer">
                      <div>{item?.name}</div>
                      <div>{item?.position}</div>
                      <div>{item?.company}</div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div> */}

        <div className="HomePageContainer_news">
          <div className="HomePageContainer_news__title">
            <div className="HomePageContainer_news__title__top">Our News</div>
            <div className="HomePageContainer_news__title__text">
              {getLabel('xinwendongtai')}
            </div>
          </div>

          <div className="HomePageContainer_news__box">
            {newsList?.map((item, index) => {
              return (
                <>
                  <div
                    className="HomePageContainer_news__box__item"
                    onClick={() => {
                      vsf?.navigateTo(`./newsDetail?id=${item?.id}`);
                    }}
                  >
                    <div className="HomePageContainer_news__box__item__img">
                      <img src={JSON.parse(item?.img)} />
                    </div>

                    <div className="HomePageContainer_news__box__item__foot">
                      <div className="HomePageContainer_news__box__item__foot__left">
                        <img
                          loading="lazy"
                          src={calanderIcon}
                          alt=""
                          className="HomePageContainer_news__box__item__foot__left__icon"
                        />

                        <>
                          <div className="HomePageContainer_news__box__item__foot__left__month">
                            {item?.dateString?.day ?? ''}
                          </div>
                          <div className="HomePageContainer_news__box__item__foot__left__monthEn">
                            {(isChinese()
                              ? item?.dateString?.chineseMonth
                              : item?.dateString?.englishMonth) ?? ''}
                          </div>
                        </>
                      </div>
                      <div className="HomePageContainer_news__box__item__foot__right">
                        <div className="HomePageContainer_news__box__item__foot__right__title">
                          {isChinese() ? item?.title : item?.enTitle}
                        </div>
                        <div className="HomePageContainer_news__box__item__foot__right__content">
                          {isChinese() ? item?.subTitle : item?.enSubTitle}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        {/* <div className="HomePageContainer_bottom">
          <div className="HomePageContainer_bottomImg">
            <img src={bottomPhoto} alt="" className="" />
          </div>
          <div className="HomePageContainer_bottom-top">
            <div className="HomePageContainer_bottom-top_title">
              {getLabel('jiaruqiaoyue')}
            </div>
            <div
              className="HomePageContainer_bottom-top_footer"
              onClick={() => {
                vsf.navigateTo('./Recruitment');
              }}
            >
              <div className="HomePageContainer_bottom-top_footer__name">
                {getLabel('jiaruwomen')}
              </div>
              <div className="HomePageContainer_bottom-top_footer__icon"></div>
            </div>
          </div>
        </div> */}

        <div className="ConnectUsContainer-bottom">
          <div className="ConnectUsContainer-bottom__left">
            <div className="ConnectUsContainer-bottom__left__title">
              Contact Us
            </div>
            <div className="ConnectUsContainer-bottom__left__text">
              {getLabel('zaixianliuyan')}
            </div>
            <div className="ConnectUsContainer-bottom__left__content">
              {getLabel('womenzhili')}
            </div>
          </div>
          <div className="ConnectUsContainer-bottom__right">
            <Form name="basic" form={form} onFinish={onFinish}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    name="companyName"
                    rules={
                      isChinese()
                        ? [{ required: true, message: '请输入公司名称!' }]
                        : [
                            {
                              required: true,
                              message: 'Please enter the company name!',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder={getLabel('gongsiming')}
                      className="customInput"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="mail"
                    rules={
                      isChinese()
                        ? [
                            { required: true, message: '请输入邮箱!' },
                            {
                              pattern: new RegExp(
                                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                              ),
                              message: '请检查邮箱格式',
                            },
                          ]
                        : [
                            {
                              required: true,
                              message: 'Please check the email format!',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder={getLabel('youxiang')}
                      className="customInput"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="name"
                    rules={
                      isChinese()
                        ? [{ required: true, message: '请输入姓名!' }]
                        : [
                            {
                              required: true,
                              message: 'Please enter your name!',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder={getLabel('xingming')}
                      className="customInput"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phone"
                    rules={
                      isChinese()
                        ? [{ required: true, message: '请输入电话!' }]
                        : [
                            {
                              required: true,
                              message: 'Please enter phone number!',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder={getLabel('shouji')}
                      className="customInput"
                      maxLength={11}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="address"
                    rules={
                      isChinese()
                        ? [{ required: true, message: '请输入地址!' }]
                        : [
                            {
                              required: true,
                              message: 'Please enter the address!',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder={getLabel('dizhi3')}
                      className="customInput"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="messageType"
                    rules={
                      isChinese()
                        ? [{ required: true, message: '请选择类型!' }]
                        : [
                            {
                              required: true,
                              message: 'Please select a type!',
                            },
                          ]
                    }
                  >
                    <Select
                      className="customSelect"
                      placeholder={getLabel('leixing')}
                      dataSource={isChinese() ? selectOptions : selectOptionsEn}
                    />
                  </Form.Item>
                </Col>

                <Col span={36}>
                  <Form.Item
                    name="content"
                    rules={
                      isChinese()
                        ? [{ required: true, message: '请输入留言内容!' }]
                        : [
                            {
                              required: true,
                              message: 'Please enter the message content!',
                            },
                          ]
                    }
                  >
                    <TextArea
                      placeholder={getLabel('liuyan')}
                      className="customTextArea"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    height: '3.875rem',
                  }}
                >
                  {getLabel('tijiao')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default definePage(HomePage);
