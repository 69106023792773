import vsf, { defineStore } from '@vs/vsf-boot';

import storage from '@/utils/storage';

const zhCn = defineStore({
  language: storage?.get('language') ?? 'english',
  setLanguage: (_language) => {
    zhCn.language = _language;
    storage.set('language', _language);
  },
});

export default zhCn;
