import './productSpeDetail.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { Section, Carousel, message, Card } from '@vs/vsf-kit';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import group from '@/assets/icon/Group.png';
import home from '@/assets/icon/home.png';
import left from '@/assets/icon/left.png';
import right from '@/assets/icon/right.png';
import leftIcon from '@/assets/icon/leftIcon.png';
import rightIcon from '@/assets/icon/rightIcon.png';
import downLoadIcon from '@/assets/icon/downLoadIcon.png';
import disDownLoad from '@/assets/icon/disDownLoad.png';
import bottomImg from '@/assets/image/productDetailBottom.png';
import { getLabel, isChinese, getImages } from '@/config/zhCn_config';
const productSpeDetail = (props) => {
  const [detail, setDetail] = useState({});
  const [productDetail, setProductDetail] = useState(0);
  const [enArgumentImgIndex, setEnArgumentImgIndex] = useState(0);
  const [argumentImgIndex, setArgumentImgIndex] = useState(0);
  const [relatedList, setRelatedList] = useState([]);
  const [relatedIndex, setRelatedIndex] = useState(0);
  const carouselRef = useRef(null);
  const carouselEnRef = useRef(null);

  const { Meta } = Card;

  const type = props?.routes?.query?.type;
  const id = props?.routes?.query?.id;

  const loadSwiperInfo = () => {
    // if (type === 'ACCESSORIES') {
    //   vsf?.services
    //     ?.AttachmentController_getAttachmentVoById_e385b6?.({
    //       id: id,
    //     })
    //     .then((resposne) => {
    //       if (resposne && resposne?.code === 200) {
    //         setDetail({
    //           ...resposne?.data,
    //           topImg: JSON.parse(resposne?.data?.topImg ?? '[]'),
    //           enTopImg: JSON.parse(resposne?.data?.enTopImg ?? '[]'),
    //           argumentImg: JSON.parse(resposne?.data?.argumentImg ?? '[]'),
    //           enImg: JSON.parse(resposne?.data?.enImg ?? '[]'),
    //           img: JSON.parse(resposne?.data?.img ?? '[]'),
    //           productDetail: JSON.parse(resposne?.data?.productDetail ?? '[]'),
    //         });
    //       }
    //     });
    // } else {

    // }

    vsf?.services
      ?.CoreProductController_getCoreProductVoById_1f53b5?.({
        id: id,
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          const related = resposne?.data?.related?.map((ele) => {
            return {
              ...ele,
              img: JSON.parse(ele?.img ?? '[]'),
            };
          });
          setRelatedList(related?.splice(0, 3));

          setDetail({
            ...resposne?.data,
            topImg: JSON.parse(resposne?.data?.topImg ?? '[]'),
            enTopImg: JSON.parse(resposne?.data?.enTopImg ?? '[]'),
            argumentImg: JSON.parse(resposne?.data?.argumentImg ?? '[]'),
            enImg: JSON.parse(resposne?.data?.enImg ?? '[]'),
            enArgumentImg: JSON.parse(resposne?.data?.enArgumentImg ?? '[]'),
            enAdvantageImg: JSON.parse(resposne?.data?.enAdvantageImg ?? '[]'),
            img: JSON.parse(resposne?.data?.img ?? '[]'),
            productDetail: JSON.parse(resposne?.data?.productDetail ?? '[]'),
            related: related,
          });
        }
      });
  };

  useEffect(() => {
    loadSwiperInfo();
    window.scrollTo(0, 0);
  }, [id]);

  const downLoad = (url, filename) => {
    if (url) {
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
    }
  };

  return (
    <Section>
      <div className="productSpeDetailContainer">
        <div className="productSpeDetailContainer-titleImg">
          <img src={isChinese() ? detail?.topImg : detail?.enTopImg} alt="" />
        </div>

        <div className="productSpeDetailContainer-content">
          <div className="productSpeDetailContainer-content__title">
            <img
              src={home}
              alt=""
              className="productSpeDetailContainer-content__title__home"
            />
            <img
              src={group}
              alt=""
              className="productSpeDetailContainer-content__title__group"
            />
            <div
              className="newsDetailContainer-content__title__textNav"
              onClick={() => {
                vsf?.navigateTo('./Product');
              }}
            >
              {getLabel('chanpin')}
            </div>
            <img
              src={group}
              alt=""
              className="productSpeDetailContainer-content__title__group"
            />
            <div className="productSpeDetailContainer-content__title__text">
              {type === 'MINI_INVERTER'
                ? getLabel('miniInverter')
                : type === 'OUTDOOR_INVERTER'
                  ? getLabel('outdoorInverter')
                  : type === 'COMMERCE_INVERTER'
                    ? getLabel('commerceInverter')
                    : getLabel('attachment')}
            </div>
          </div>
        </div>
        {(detail?.descr || detail?.enDescr) && (
          <div
            className="productSpeDetailContainer-desc"
            dangerouslySetInnerHTML={{
              __html: isChinese() ? detail?.descr : detail?.enDescr,
            }}
          />
        )}
        <div className="productSpeDetailContainer-img">
          {(isChinese() ? detail?.enImg : detail?.enAdvantageImg)?.map(
            (item) => {
              return (
                <>
                  <div className="productSpeDetailContainer-img__box">
                    <img src={item} alt="" loading="lazy" />
                  </div>
                </>
              );
            },
          )}
        </div>
        {/* {type !== 'ACCESSORIES' && (
          <> */}
        <div className="productSpeDetailContainer-swiper">
          <div className="productSpeDetailContainer-swiper__title">
            <div className="productSpeDetailContainer-swiper__title__top">
              Product Parameters
            </div>
            <div className="productSpeDetailContainer-swiper__title__text">
              {getLabel('chanpincanshu')}
            </div>
          </div>
          <div className="productSpeDetailContainer-swiper__box">
            <div className="productSpeDetailContainer-swiper__box__left">
              {/* <div className="productSpeDetailContainer-swiper__box__left__item">
                {detail?.argumentImg?.map((item, index) => {
                  return (
                    <>
                      <div className="productSpeDetailContainer-swiper__box__left__item__img">
                        <img
                          src={detail?.argumentImg[argumentImgIndex]}
                          alt=""
                          key={index}
                          loading="lazy"
                        />
                      </div>
                    </>
                  );
                })}
              </div>

              <div className="productSpeDetailContainer-swiper__box__left__dots">
                {detail?.argumentImg?.map((item, index) => {
                  return (
                    <>
                      <div
                        className={
                          index === argumentImgIndex
                            ? 'left__item__dots'
                            : 'left__item__dotsNot'
                        }
                        onClick={() => {
                          setArgumentImgIndex(index);
                        }}
                      ></div>
                    </>
                  );
                })}
              </div> */}
              <div className="productSpeDetailContainer-swiper__box__left__item">
                <Carousel dotPosition={'bottom'} dots={false} ref={carouselRef}>
                  {detail?.argumentImg?.map((item, index) => {
                    return (
                      <>
                        <div
                          className="productSpeDetailContainer-swiper__box__left__item__img"
                          key={index}
                        >
                          <img src={item} alt="" loading="lazy" />
                        </div>
                      </>
                    );
                  })}
                </Carousel>

                <div className="productSpeDetailContainer-swiper__box__left__dots">
                  {detail?.argumentImg?.map((item, index) => {
                    return (
                      <>
                        <div
                          className={
                            index === argumentImgIndex
                              ? 'left__item__dots'
                              : 'left__item__dotsNot'
                          }
                          onClick={() => {
                            setArgumentImgIndex(index);
                            carouselRef.current.goTo(index);
                          }}
                        ></div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="productSpeDetailContainer-swiper__box__right">
              {productDetail > 0 && (
                <>
                  <div className="productSpeDetailContainer-swiper__box__right__prev">
                    <img
                      src={left}
                      alt=""
                      loading="lazy"
                      onClick={() => {
                        isChinese()
                          ? setProductDetail(productDetail - 1)
                          : setEnArgumentImgIndex(enArgumentImgIndex - 1);
                        isChinese()
                          ? carouselEnRef.current.goTo(productDetail - 1)
                          : carouselEnRef.current.goTo(enArgumentImgIndex) - 1;
                      }}
                    />
                  </div>
                </>
              )}

              <div className="productSpeDetailContainer-swiper__box__right__img">
                <Carousel
                  dotPosition={'bottom'}
                  dots={false}
                  ref={carouselEnRef}
                >
                  {(isChinese()
                    ? detail?.productDetail
                    : detail?.enArgumentImg
                  )?.map((item, index) => {
                    return (
                      <>
                        <div className="productSpeDetailContainer-swiper__box__right__img__src">
                          <img src={item} alt="" key={index} loading="lazy" />
                        </div>
                      </>
                    );
                  })}
                </Carousel>
              </div>
              {(isChinese()
                ? productDetail + 1 <= detail?.productDetail?.length - 1
                : enArgumentImgIndex + 1 <=
                  detail?.enArgumentImg?.length - 1) && (
                <>
                  <div className="productSpeDetailContainer-swiper__box__right__next">
                    <img
                      src={right}
                      loading="lazy"
                      alt=""
                      onClick={() => {
                        isChinese()
                          ? setProductDetail(productDetail + 1)
                          : setEnArgumentImgIndex(enArgumentImgIndex + 1);
                        isChinese()
                          ? carouselEnRef.current.goTo(productDetail + 1)
                          : carouselEnRef.current.goTo(enArgumentImgIndex + 1);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="productSpeDetailContainer-swiper__downLoad">
            <div className="productSpeDetailContainer-swiper__downLoad__item">
              {getLabel('jishucanshu')}
              <div className="productSpeDetailContainer-swiper__downLoad__item__img">
                <img
                  src={detail?.argument ? downLoadIcon : disDownLoad}
                  alt=""
                  className="downLoadIcon"
                  onClick={() => {
                    downLoad(
                      JSON.parse(detail?.argument),
                      getLabel('jishucanshu'),
                    );
                  }}
                />
              </div>
            </div>

            <div className="productSpeDetailContainer-swiper__downLoad__item">
              {getLabel('renzhengzhengshu')}{' '}
              <div className="productSpeDetailContainer-swiper__downLoad__item__img">
                <img
                  src={detail?.certificate ? downLoadIcon : disDownLoad}
                  alt=""
                  className="downLoadIcon"
                  onClick={() => {
                    downLoad(
                      JSON.parse(detail?.certificate),
                      getLabel('renzhengzhengshu'),
                    );
                  }}
                />
              </div>
            </div>

            <div className="productSpeDetailContainer-swiper__downLoad__item">
              {getLabel('yonghushouce')}{' '}
              <div className="productSpeDetailContainer-swiper__downLoad__item__img">
                <img
                  src={detail?.handbook ? downLoadIcon : disDownLoad}
                  alt=""
                  className="downLoadIcon"
                  onClick={() => {
                    downLoad(
                      JSON.parse(detail?.handbook),
                      getLabel('yonghushouce'),
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <img
          className="productSpeDetailContainer-bottomImg"
          src={getImages('bottomImg')}
        ></img>

        {relatedList?.length > 0 && (
          <>
            <div className="productSpeDetailContainer-bottom">
              <div className="productSpeDetailContainer-bottom__title">
                <div className="productSpeDetailContainer-bottom__title__top">
                  Related Products
                </div>
                <div className="productSpeDetailContainer-bottom__title__text">
                  {getLabel('xiangguanchanpin')}
                </div>
              </div>
              <div className="productSpeDetailContainer-bottom__box">
                {relatedIndex > 0 && (
                  <>
                    <img
                      src={leftIcon}
                      alt=""
                      className="leftIcon"
                      onClick={() => {
                        setRelatedIndex(relatedIndex - 1);
                      }}
                    />
                  </>
                )}

                {relatedList?.map((item) => {
                  return (
                    <>
                      <div
                        className="productSpeDetailContainer-bottom__box__item"
                        onClick={() => {
                          vsf?.navigateTo(
                            `./productSpeDetail?type=${type}&id=${item?.id}`,
                          );
                          location.reload();
                        }}
                      >
                        <div className="productSpeDetailContainer-bottom__box__item__title">
                          <img src={item?.img} alt="" />
                        </div>

                        <div className="productSpeDetailContainer-bottom__box__item__name">
                          {isChinese() ? item?.name : item?.enName}
                        </div>
                        <div
                          className="productSpeDetailContainer-bottom__box__item__text"
                          dangerouslySetInnerHTML={{
                            __html: isChinese()
                              ? item?.content
                              : item?.enContent,
                          }}
                        ></div>
                      </div>
                    </>
                  );
                })}
                {relatedIndex + 1 <= detail?.related?.length - 1 && (
                  <>
                    <img
                      src={leftIcon}
                      alt=""
                      className="leftIcon"
                      onClick={() => {
                        setRelatedIndex(relatedIndex - 1);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </>
        )}
        {/* </>
        )} */}

        <div className="productSpeDetailContainer-line"></div>
      </div>
    </Section>
  );
};

export default definePage(productSpeDetail);
